// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    overflow-y: auto;
    height: 100%;
}

.client-list {
    padding: 20px;
    max-height: 100vh;
    overflow-y: auto;
  }
  
  .loading, .error {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
  }
  
  .delete-confirmation {
    background-color: #4CAF50;
    color: white;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters input, .filters select {
    margin-right: 10px;
    padding: 5px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .url-cell, td {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  a {
    color: #1a0dab;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/components/ClientList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["body, html {\n    overflow-y: auto;\n    height: 100%;\n}\n\n.client-list {\n    padding: 20px;\n    max-height: 100vh;\n    overflow-y: auto;\n  }\n  \n  .loading, .error {\n    text-align: center;\n    margin-top: 20px;\n    font-size: 18px;\n  }\n  \n  .delete-confirmation {\n    background-color: #4CAF50;\n    color: white;\n    padding: 15px;\n    margin-bottom: 20px;\n    border-radius: 4px;\n    text-align: center;\n    font-weight: bold;\n  }\n  \n  .filters {\n    margin-bottom: 20px;\n  }\n  \n  .filters input, .filters select {\n    margin-right: 10px;\n    padding: 5px;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  th {\n    background-color: #f2f2f2;\n    cursor: pointer;\n  }\n  \n  tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .url-cell, td {\n    max-width: 200px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  \n  a {\n    color: #1a0dab;\n    text-decoration: none;\n  }\n  \n  a:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
