import React, { createContext, useState, useContext, useEffect } from 'react';

import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  onIdTokenChanged,
} from "firebase/auth";

import firebaseAuth from "./config/firebaseConfig";
import { setAuthToken } from "./services/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unsubscribeAuthState = onAuthStateChanged(firebaseAuth, async (user) => { // Added async
      if (user) {
        setIsSignedIn(true);
        try {
          const token = localStorage.getItem("jwtToken");
          if (token) {
            setAuthToken(token);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        setIsSignedIn(false);
        
        setAuthToken(null); // Clear the token when user is signed out
      }
    });

    // Set up token refresh listener
    const unsubscribeIdToken = onIdTokenChanged(firebaseAuth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token);
      } else {
        setAuthToken(null);
      }
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, []);

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken();
      setAuthToken(idToken);  // Set token for future requests
      console.info("User signed in successfully : ", userCredential.user);
      return;
    } catch (err) {
      console.error(err.message);
      return err.message;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(firebaseAuth);
      setAuthToken(null);
      console.info("User signed out successfully");
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);