import React, { useState, useEffect } from 'react';
import { api } from '../services/api';
import EditClientModal from './EditClientModal';
import './ClientList.css';

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    companyName: '',
    clientId: '',
    status: '',
    email: ''
  });
  const [editingClient, setEditingClient] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await api.get(`v1/client/retrieve_all`, {
        });
        setClients(response.data.data);
        setFilteredClients(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching clients');
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const results = clients.filter(client =>
      client.company_name.toLowerCase().includes(filters.companyName.toLowerCase()) &&
      client.client_id.toString().includes(filters.clientId) &&
      client.status.toLowerCase().includes(filters.status.toLowerCase()) &&
      (Array.isArray(client.email) 
        ? client.email.some(email => email.toLowerCase().includes(filters.email.toLowerCase()))
        : client.email.toLowerCase().includes(filters.email.toLowerCase()))
    );
    setFilteredClients(results);
  }, [filters, clients]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const renderMultipleValues = (values) => {
    if (Array.isArray(values)) {
      return values.map((value, index) => (
        <div key={index}>{value}</div>
      ));
    }
    return values;
  };

  const handleEditClient = (client) => {
    setEditingClient(client);
  };

  const handleUpdateClient = (updatedClient) => {
    setClients(prevClients => 
      prevClients.map(client => 
        client.client_id === updatedClient.client_id ? updatedClient : client
      )
    );
    setEditingClient(null);
  };

  const handleDeleteClient = (clientId, companyName) => {
    setDeleteConfirmation({ clientId, companyName });
  };

  const confirmDeleteClient = () => {
    if (deleteConfirmation) {
      setClients(prevClients => 
        prevClients.filter(client => client.client_id !== deleteConfirmation.clientId)
      );
      setDeleteConfirmation(null);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="client-list">
      <h2>Client List</h2>
      {deleteConfirmation && (
        <div className="delete-confirmation">
          Client {deleteConfirmation.companyName} has been deleted successfully.
        </div>
      )}
      <div className="filters">
        <input
          type="text"
          name="companyName"
          placeholder="Filter by company name"
          value={filters.companyName}
          onChange={handleFilterChange}
        />
        <input
          type="text"
          name="status"
          placeholder="Filter by status"
          value={filters.status}
          onChange={handleFilterChange}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Client ID</th>
            <th>URL(s)</th>
            <th>Email(s)</th>
            <th>Status</th>
            <th>CRM</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients.map((client) => (
            <tr key={client.client_id}>
              <td>{client.company_name}</td>
              <td>{client.client_id}</td>
              <td className="url-cell">{renderMultipleValues(client.url)}</td>
              <td>{renderMultipleValues(client.email)}</td>
              <td>{client.status}</td>
              <td>{client.crm}</td>
              <td>
                <button onClick={() => handleEditClient(client)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingClient && (
        <EditClientModal
          clientId={editingClient.client_id}
          onClose={() => setEditingClient(null)}
          onUpdate={handleUpdateClient}
          onDelete={handleDeleteClient}
        />
      )}
    </div>
  );
};

export default ClientList;