import React, { useEffect, useState } from "react";
import { MdInfo } from "react-icons/md";
import MessageItem from './MessageItem';
import "./Conversation.css";
import { getLeads, triggerCaptureLead } from '../services/api';

const AUTHORIZATION = process.env.REACT_APP_API_AUTH_KEY;

function Modal({ isOpen, onClose, onSubmit }) {
    const [leadName, setLeadName] = useState('');
    const [leadEmail, setLeadEmail] = useState('');
    const [leadPhone, setLeadPhone] = useState('');

    if (!isOpen) return null;

    const handleSubmit = () => {
        onSubmit({
            name: leadName,
            email: leadEmail,
            phone: leadPhone || null
        });
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Enter Lead Details</h2>
                <input
                    type="text"
                    placeholder="Lead Name"
                    value={leadName}
                    onChange={(e) => setLeadName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Lead Email"
                    value={leadEmail}
                    onChange={(e) => setLeadEmail(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Lead Phone"
                    value={leadPhone}
                    onChange={(e) => setLeadPhone(e.target.value)}
                />
                <button onClick={handleSubmit}>Submit</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
}

export default function ConversationItem(props) { 
    const { displayed } = props;
    const [leads, setLeads] = useState([]);
    const [leadTrigger, setLeadTrigger] = useState("No");
    const [errors, setErrors] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchLeads = async (clientId) => {
            try {
              const response = await getLeads(clientId);
              setLeads(response.data);
            } catch (error) {
                setErrors(prevErrors => [...prevErrors, 'Error fetching leads']);
            }
        };
        fetchLeads(displayed.client_id);
    }, [displayed]);

    useEffect(() => {
        let found = false;
        leads.forEach(lead => {
            if (lead.id === displayed.thread_id) {
                setLeadTrigger("Yes");
                found = true;
            }
        });
        if (!found) {
            setLeadTrigger("No");
        }
    }, [leads]);

    const triggerTool = async (leadEmail, leadName, leadPhone, assistantId, threadId, authorization) => {
        try {
          const response = await triggerCaptureLead(leadEmail, leadName, leadPhone, assistantId, threadId, authorization);
          console.log("triggering capture_lead tool : ", response.data)
        } catch (error) {
            setErrors(prevErrors => [...prevErrors, 'Error Triggering Tool']);
        }
    };

    const handleTriggerTool = (leadDetails) => {
        triggerTool(
            leadDetails.email,
            leadDetails.name,
            leadDetails.phone,
            displayed.assistant_id,
            displayed.thread_id,
            AUTHORIZATION
        );
    };

    if (errors.length > 0) return <div className="error">{errors.join('. ')}</div>;

    if (displayed.source) {
        if (displayed.source.url == "no source url found") {
            displayed.source.url = "sandbox";
        }
    }

    return (
        <div className='selected-conversation-container'>
            <div className='selected-conversation-infos'>
                <MdInfo style={{float: 'right', width: '20px', height: '20px'}} color='inherit'/>
                <button
                    style={{ float: 'right', marginTop: '30px' }}
                    onClick={() => setModalOpen(true)}
                >
                    Trigger Tool
                </button>
                <p>ID : {displayed.thread_id}</p>
                <p>Assistant : {displayed.assistant_id}</p>
                <p>Device : {displayed.source ? displayed.source.device : 'N/A'}</p>
                <p>Source : {displayed.source ? displayed.source.url : 'N/A'}</p>
                <p>Lead Trigger : {leadTrigger}</p>
            </div>

            <div className='selected-conversation-content'>
                {displayed.messages.map(message => (
                    <div key={message.message_id}>
                        <MessageItem bubble={message} />
                    </div>
                ))}
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleTriggerTool}
            />
        </div>
    );
}