import React, { useState, useEffect } from 'react';
import './EditClientModal.css';
import { api } from '../services/api';


const EditClientModal = ({ clientId, onClose, onUpdate, onDelete }) => {
  const [originalClient, setOriginalClient] = useState(null);
  const [editedClient, setEditedClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmationName, setConfirmationName] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const statusOptions = ['demo', 'trial', 'inactive', 'active'];

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await api.get(`v1/client/retrieve/${clientId}`);
        setOriginalClient(response.data);
        setEditedClient({});
        setLoading(false);
      } catch (err) {
        console.error('Error fetching client details:', err);
        setError('Failed to fetch client details');
        setLoading(false);
      }
    };

    fetchClientDetails();
  }, [clientId]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedClient(prev => ({ ...prev, [name]: value }));
  };

  const getFieldValue = (field) => {
    const value = editedClient[field] !== undefined ? editedClient[field] : originalClient.data[field];
    if (field === 'url' || field === 'email') {
      return Array.isArray(value) ? value : (value ? [value] : []);
    }
    return value;
  };

  const handleArrayChange = (field, index, value) => {
    const currentArray = getFieldValue(field);
    const newArray = [...currentArray];
    newArray[index] = value;
    setEditedClient(prev => ({ ...prev, [field]: newArray }));
  };

  const addArrayItem = (field) => {
    const currentArray = getFieldValue(field);
    setEditedClient(prev => ({
      ...prev,
      [field]: [...currentArray, '']
    }));
  };

  const removeArrayItem = (field, index) => {
    const currentArray = getFieldValue(field);
    setEditedClient(prev => ({
      ...prev,
      [field]: currentArray.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const confirmUpdate = async () => {
    if (confirmationName === originalClient.data.company_name) {
      try {
        const updatedClient = { 
          ...originalClient.data, 
          ...editedClient,
          last_modified: new Date().toISOString()
        };
        const response = await api.put(`v1/client/update/${clientId}`, updatedClient);
        onUpdate(response.data);
        onClose();
      } catch (error) {
        console.error('Error updating client:', error.response || error);
        setError('Failed to update client');
      }
    } else {
      setError('The company name entered does not match. Update cancelled.');
    }
    setShowConfirmation(false);
  };
  
  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`v1/client/delete/${clientId}`);
      onDelete(clientId, originalClient.data.company_name);
      onClose();
    } catch (error) {
      console.error('Error deleting client:', error.response || error);
      setError('Failed to delete client');
    }
    setShowDeleteConfirmation(false);
  };

  if (loading) return <div className="modal"><div className="modal-content">Loading...</div></div>;
  if (error) return <div className="modal"><div className="modal-content">Error: {error}</div></div>;
  if (!originalClient) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Edit Client: {originalClient.data.company_name}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Company Name:</label>
            <input
              name="company_name"
              value={getFieldValue('company_name') || ''}
              onChange={handleChange}
              placeholder={originalClient.data.company_name}
            />
          </div>
          <div>
            <label>URL(s):</label>
            {getFieldValue('url').map((url, index) => (
              <div key={index} className="array-input">
                <input
                  value={url}
                  onChange={(e) => handleArrayChange('url', index, e.target.value)}
                  placeholder={`URL ${index + 1}`}
                />
                <button type="button" onClick={() => removeArrayItem('url', index)}>Remove</button>
              </div>
            ))}
            <button type="button" onClick={() => addArrayItem('url')}>Add URL</button>
          </div>
          <div>
            <label>Email(s):</label>
            {getFieldValue('email').map((email, index) => (
              <div key={index} className="array-input">
                <input
                  value={email}
                  onChange={(e) => handleArrayChange('email', index, e.target.value)}
                  placeholder={`Email ${index + 1}`}
                />
                <button type="button" onClick={() => removeArrayItem('email', index)}>Remove</button>
              </div>
            ))}
            <button type="button" onClick={() => addArrayItem('email')}>Add Email</button>
          </div>
          <div>
            <label>Status:</label>
            <select
              name="status"
              value={getFieldValue('status') || ''}
              onChange={handleChange}
            >
              {statusOptions.map(option => (
                <option key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>CRM:</label>
            <input
              name="crm"
              value={getFieldValue('crm') || ''}
              onChange={handleChange}
              placeholder={originalClient.data.crm}
            />
          </div>
          <div className="button-group">
            <button type="submit" className="update-button">Update</button>
            <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
            <button type="button" className="delete-button" onClick={handleDelete}>
              🚨 Delete Client
            </button>
          </div>
        </form>
        {showConfirmation && (
          <div className="confirmation-modal">
            <p>This modification cannot be reverted. Please type the company name to confirm:</p>
            <input
              value={confirmationName}
              onChange={(e) => setConfirmationName(e.target.value)}
              placeholder="Enter company name"
            />
            <div className="button-group">
              <button onClick={confirmUpdate}>Confirm Update</button>
              <button onClick={() => setShowConfirmation(false)}>Cancel</button>
            </div>
          </div>
        )}
        {showDeleteConfirmation && (
          <div className="confirmation-modal">
            <p>Are you sure you want to delete this client? This action cannot be undone.</p>
            <div className="button-group">
              <button onClick={confirmDelete}>Yes, Delete Client</button>
              <button onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditClientModal;