import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDCFryUoUB8uC7eCB28I6GFmEp3wPFyA_U",
    authDomain: "capture-408321.firebaseapp.com",
    projectId: "capture-408321",
    storageBucket: "capture-408321.appspot.com",
    messagingSenderId: "42329114879",
    appId: "1:42329114879:web:2fe57b8870b9275d77a4ca"
    };    

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;